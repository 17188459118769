import React, { useState, useEffect } from 'react'
import Button from '../buttons/confirm/confirm'
import TextInput from '../textfields/plainText'
import Dropdown from '../dropdown/dropdown'

export default function ModalCreateNode(props) {
  const [label, setLabel] = useState('Pick Label')
  const [name, setName] = useState('')
  const [dropdownOptions, setDropdownOptions] = useState(['company', 'person', 'vehicle', 'part'])

  const handleChangeLabel = (label) => {
    setLabel(label)
  }

  const handleChangeName = (event) => {
    setName(event.target.value)
  }

  const handleClick = async () => {
    if (name && label !== 'Pick Label') {
      const body = {
        label: label,
        name: name,
        owner: props.user.owner,
        group: props.user.group
      }
      const res = await props.sendRequest('node', 'POST', body)
      //props.refresh()
      let graphData = props.graphData
      graphData.nodes.push(res)
      let newGraphData = {...graphData}
      props.setGraphData(newGraphData)
      setName('')
      props.closeModal()
    }
  }

  useEffect(() => {
    try {
      let labels = props.configNode.nodelabels
      setDropdownOptions(labels.split('|'))
    } catch (err) {
      console.log(err)
    }
  }, [props.configNode])

  return (
    <div className="is-flex is-flex-direction-column p-2">
      <label className="label mt-2 mb-2">Node Label</label>
      <Dropdown select={handleChangeLabel} label={label} options={dropdownOptions} />
      <label className="label mt-2 mb-2">Node Name</label>
      <TextInput placeholder="name" value={name} onChange={handleChangeName} />
      <div className="m-4" />
      <Button value={`Create Node`} click={handleClick} />
      <div className="m-2" />
    </div>
  )

}