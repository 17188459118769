import React from 'react'
import context from '../context'

const Expand = (props) => {
  const { root } = React.useContext(context)
  const selectAdd = root.select.add
  const id = root.node.id()
  const links = root.data.links()

  const [isExpanded, setExpanded] = React.useState(false)

  const selectAll = () => {
    selectAdd(
      links
        .filter((link) => link.source.id === id || link.target.id === id)
        .map((rel) => (rel.source.id === id ? rel.target : rel.source))
    )
  }

  const selectIn = () => {
    selectAdd(
      links.filter((link) => link.target.id === id).map((rel) => rel.source)
    )
  }

  const selectOut = () => {
    selectAdd(
      links.filter((link) => link.source.id === id).map((rel) => rel.target)
    )
  }

  const DropDownButton = ({ children, func }) => (
    <button
      className='dropdown-item button is-shadowless is-radiusless'
      onClick={() => {
        func()
        props.closeContextMenu()
      }}
    >
      {children}
    </button>
  )

  return (
    <>
      <button
        className='dropdown-item button is-shadowless is-radiusless'
        onClick={() => setExpanded(!isExpanded)}
      >
        Expand selection
      </button>
      {isExpanded ? (
        <>
          <DropDownButton func={selectAll}>All</DropDownButton>
          <DropDownButton func={selectIn}>In</DropDownButton>
          <DropDownButton func={selectOut}>Out</DropDownButton>
        </>
      ) : null}
    </>
  )
}

export default Expand
