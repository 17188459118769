export default function DeletePropertyButton(props) {

  const handleDeleteProperty = async () => {
    const body = {
      nodeKey: 'uuid',
      nodeValue: props.selectedObject.uuid,
      propKey: props.keyName
    }
    if (props.selectedObject.type === 'node') {
      await props.sendRequest('node/property', 'DELETE', body)
    } else {
      await props.sendRequest('relationship/property', 'DELETE', body)
    }
    props.deleteProperty(props.keyName)
  }

  return (
    <input className="box button is-danger" type="button" value='Delete' onClick={handleDeleteProperty} />
  )
}