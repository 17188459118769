import React, { useState, useEffect } from 'react'

const DataContext = React.createContext()

const DataProvider = ({ children }) => {
  const [data, setData] = useState({ nodes: [], links: [] })
  const [select, setSelect] = useState([])
  const [user, setUser] = useState(null)

  useEffect(() => console.log('Select:', select), [select])

  class User {
    exists = () => user !== null
    get = () => user
    owner = () => this.get().owner
    set = (user) => {
      if (typeof user === 'object') {
        console.log('User changed:', user)
        setUser(user)
      }
    }
  }

  class Data {
    get = () => data
    nodes = () => data.nodes
    links = () => data.links
    set = (data) => {
      if (typeof data === 'object') {
        setData(data)
        console.log('Data changed:', data)
      }
    }
    empty = () => setData({ nodes: [], links: [] })
    addNode = (node) =>
      this.set({ nodes: [...data.nodes, node], links: [...data.links] })
    addLink = (link) => {
      this.set({ nodes: [...data.nodes], links: [...data.links, link] })
    }
  }

  class Node {
    #node = ''

    get = () => this.#node
    id = () => this.#node.id
    set = (node) => (this.#node = node)
  }

  class Select {
    #startId = 1171632

    get = () => select
    getStartId = () => this.#startId
    set = (selected) =>
      setSelect(selected.filter((item) => item.id !== this.#startId))
    add = (selected) => {
      const newNodes = []
      selected.forEach((node) => {
        if (!select.find((n) => n.id === node.id)) newNodes.push(node)
      })
      this.set([...select, ...newNodes])
    }
  }

  class Root {
    data = new Data()
    select = new Select()
    node = new Node()
    user = new User()
  }

  const root = new Root()

  return (
    <DataContext.Provider value={{ root }}>{children}</DataContext.Provider>
  )
}

export default DataContext
export { DataProvider }
