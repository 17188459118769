import React, { useState } from 'react'
import Button from '../buttons/confirm/confirm'
import TextInput from '../textfields/plainText'
import Dropdown from '../dropdown/dropdown'

export default function ModalUploadFile(props) {
  const [fileType, setFileType] = useState('Pick Type')
  const [fileName, setFileName] = useState('')
  const [fileTitle, setFileTitle] = useState('')
  //const [dropdownOptions/* , setDropdownOptions */] = useState(['Picture', 'Video', 'Audio', 'File'])
  const [dropdownOptions/* , setDropdownOptions */] = useState(['Picture'])

  const handleChangeLabel = (label) => {
    setFileType(label)
  }

  const handleNodeChangeName = (event) => {
    setFileName(event.target.value)
  }

  const handleRelChangeName = (event) => {
    setFileTitle(event.target.value)
  }

  const handleClick = async () => {
    if (document.querySelector('#filePicker').files.length > 0 && fileType !== 'Pick Type' && fileName) {
      const token = localStorage.getItem('token')
      const file = document.querySelector('#filePicker').files[0]
      let graphData = props.graphData
      console.log(file)
      let form = new FormData()
      form.append('file', file)
      form.append('name', fileName)
      form.append('type', fileType)
      form.append('title', fileTitle ? fileTitle : file.name)
      form.append('pathFile', `/default-domain/workspaces/Asset_management_ws/${fileType}`)

      const res = await fetch(`${props.baseUrl}/api/v1/upload`, {
        method: 'POST',
        headers: {
          Authorization: 'bearer ' + token
        },
        body: form
      })

      const resFile = await res.json()

      const bodyNode = {
        label: 'file',
        name: fileType,
        owner: 'system',
        group: 'system',
        option: {
          'file name': fileName,
          title: fileTitle ? fileTitle : file.name,
          fileUUID: resFile.fileUUID
        }
      }
      const createdNode = await props.sendRequest('node', 'POST', bodyNode)
      const relType = `HAS_${fileType}`
      const bodyRel = {
        startKey: 'uuid',
        startValue: props.selectedObject.uuid,
        endKey: 'uuid',
        endValue: createdNode.uuid,
        relName: relType.toUpperCase()
      }
      const createdRel = await props.sendRequest('relationship', 'POST', bodyRel)
      graphData.nodes.push(createdNode)
      graphData.links.push(createdRel)
      let newGraphData = {...graphData}
      props.setGraphData(newGraphData)
      props.closeModal()
    }
  }

  return (
    <div className='is-flex is-flex-direction-column p-2'>
      <div className='is-flex is-flex-direction-row'>
        <div className='is-flex is-flex-direction-column'>
          <label className='label mt-2 mb-2'>File Type</label>
          <Dropdown select={handleChangeLabel} label={fileType} options={dropdownOptions} />
        </div>
        <div className='is-flex is-flex-direction-column'>
          <label className='label mt-2 mb-2'>Relationship direction</label>
          <div className='control'>
            <input id='filePicker' type='file' accept='image/jpeg, image/png' />
          </div>
        </div>
      </div>
      <div className='is-flex is-flex-direction-row'>
        <div className='is-flex is-flex-direction-column'>
          <label className='label mt-2 mb-2'>New File Name</label>
          <TextInput placeholder='name' value={fileName} onChange={handleNodeChangeName} />
        </div>
        <div className='is-flex is-flex-direction-column'>
          <label className='label mt-2 mb-2'>File Title</label>
          <TextInput placeholder='name' value={fileTitle} onChange={handleRelChangeName} />
        </div>
      </div>
      <div className='m-4' />
      <Button value={`Upload File`} click={handleClick} />
      <div className='' />
      <img src='' alt='' />
    </div>
  )

}