import React, { useState } from 'react'
import {
  Box,
  IconButton,
  Modal,
  Button,
  Alert,
  AlertTitle,
  CircularProgress,
} from '@mui/material'
import { BiImport } from 'react-icons/bi'
import context from '../context'
import CSVToArray from './csvToArray'
import MyAxios from '../axios'
import { v4 as uuidv4 } from 'uuid'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
}

const Import = () => {
  const { root } = React.useContext(context)

  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [warning, setWarning] = useState('')

  const fileReader = new FileReader()

  let fileName = null
  let maintenance = false
  const targetReg = new RegExp('RILO0-TPLMA_DFLT|PLPOD-TPLNR|PLPOD-EQUNR', 'i')
  const missingLinks = []
  const existingNodes = []

  const finish = () => {
    setFile(null)
    setOpen(false)
    setLoading(false)
    setWarning('')

    fileName = null
    maintenance = false
  }

  const handleOnChange = (e) => {
    setFile(e.target.files[0])
    setWarning('')
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    setWarning('')

    if (file) {
      fileReader.onload = function (event) {
        fileName = file.name
        const csvOutput = CSVToArray(event.target.result)
        checkData(csvOutput)
      }
      fileReader.readAsText(file)
    }
  }

  const checkData = (newNodes) => {
    const headers = newNodes[0]
    let passed = true

    headers.forEach((element) => {
      if (!element) {
        setWarning(warning + 'Empty column(s) found.\n')
        passed = false
      }
    })

    if (checkRowFor(headers, new RegExp('NAME', 'i'))) {
      setWarning(warning + 'NAME column is missing.\n')
      passed = false
    }
    if (checkRowFor(headers, targetReg)) {
      setWarning(
        warning +
          'RILO0-TPLMA_DFLT/PLPOD-EQUNR/PLPOD-TPLNR column is missing.\n'
      )
      passed = false
    }

    if (passed) {
      if (!maintenance) convertData(newNodes)
      else convertMaintenance(newNodes)
    } else setLoading(false)
  }

  const checkRowFor = (row, value) => {
    row.forEach((element) => {
      if (value.test(element)) return true
    })
    return false
  }

  const convertMaintenance = (newNodes) => {
    const headers = newNodes[0]
    const values = newNodes.slice(1)
    const loopId = 1

    const mergedValues = []

    let h = []
    values.forEach((row) => {
      if (row[loopId] === 'h') h = row
      else if (row[loopId] === 'd') {
        const tmp = []
        for (let i = 0; i < row.length; i++) {
          if (row[i]) tmp.push(row[i])
          else tmp.push(h[i])
        }
        mergedValues.push(tmp)
      }
    })

    maintenance = false
    convertData([headers, ...mergedValues])
  }

  const convertData = async (newData) => {
    const headers = newData[0]
    const values = newData.slice(1)
    const newNodes = []
    const newLinks = []

    values.forEach((row) => {
      const node = {}
      row.forEach((column, index) => {
        if (column && headers[index])
          node[headers[index]] = column.replace(/'/g, "\\'")
      })
      if (!exists(node)) {
        node.importFile = fileName
        node.owner = root.user.get().owner
        node.uuid = uuidv4()
        newNodes.push(node)
        const link = createLink(node, newNodes)
        if (link) {
          newLinks.push(link)
          console.log('node and link created')
        } else console.log('node created')
      }
    })

    console.log('new nodes', newNodes)
    console.log('new links', newLinks)
    if (missingLinks.length > 0) console.log('missing links', missingLinks)
    if (existingNodes.length > 0) console.log('duplicate nodes', existingNodes)

    await sendBatches(createBatches(newNodes), MyAxios.postNode)
    await sendBatches(createBatches(newLinks), MyAxios.postLink)
    console.log('Import finished.')
    finish()
  }

  const exists = (node) => {
    const keys = Object.keys(node)

    for (const oldNode of root.data.nodes()) {
      let match = true
      for (const key of keys) {
        if (oldNode[key] !== node[key]) {
          match = false
          break
        }
      }
      if (match) return true
    }
    return false
  }

  const createLink = (node, newNodes) => {
    const allNodes = [...root.data.nodes(), ...newNodes]
    const sourceReg = new RegExp('IFLOS-STRNO', 'i')

    const keys = Object.keys(node)
    const target = keys.find((key) => targetReg.test(key))

    for (const oldNode of allNodes) {
      const oldKeys = Object.keys(oldNode)
      const source = oldKeys.find((key) => sourceReg.test(key))

      if (oldNode[source] === node[target]) {
        const link = {
          from: oldNode.uuid,
          to: node.uuid,
        }
        return link
      }
    }
    missingLinks.push(node)
  }

  const createBatches = (nodes) => {
    const batchSize = 1000
    const batches = []
    for (let i = 0; i < nodes.length; i += batchSize) {
      batches.push(nodes.slice(i, i + batchSize))
    }
    return batches
  }

  const sendBatches = async (batches, request) => {
    let nr = 1
    for (const batch of batches) {
      console.log(`--sending batch nr: ${nr}--`)
      await Promise.all(
        batch.map(async (req) => {
          try {
            await request(req)
            console.log('request sent')
          } catch (error) {
            console.log(error)
          }
        })
      )
      nr++
    }
  }

  return (
    <>
      <IconButton variant='contained' onClick={() => setOpen(true)}>
        <BiImport />
      </IconButton>
      <Modal open={open} onClose={() => (loading ? null : finish())}>
        <Box sx={style}>
          <h1>Import CSV file</h1>
          <input
            type={'file'}
            accept={'.csv'}
            onChange={handleOnChange}
          ></input>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                disabled={!file}
                onClick={(e) => {
                  handleOnSubmit(e)
                }}
              >
                Import base
              </Button>
              <Button
                disabled={!file}
                onClick={(e) => {
                  maintenance = true
                  handleOnSubmit(e)
                }}
              >
                Import maintenance
              </Button>
            </>
          )}
          {warning ? (
            <Alert severity='warning'>
              <AlertTitle>Missing headers.</AlertTitle>
              {warning}
            </Alert>
          ) : null}
        </Box>
      </Modal>
    </>
  )
}

export default Import
