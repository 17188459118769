import React, { useState, useEffect, useRef } from 'react'
import TextInput from '../textfields/plainText'
import NodeList from '../list/nodeList'
import Dropdown from '../dropdown/dropdown'
import Button from '../buttons/confirm/confirm'
import { BiShow } from 'react-icons/bi'
import { MdClear } from 'react-icons/md'

export default function Searchbar(props) {
  const [label, setLabel] = useState('No Filter')
  const [dropdownOptions, setDropdownOptions] = useState([
    'No Filter',
    'company',
    'person',
    'vehicle',
    'part',
  ])
  const inputRef = useRef()

  const handleChangeLabel = (label) => {
    setLabel(label)
  }

  const escapeRegex = (string) => {
    // eslint-disable-next-line
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  useEffect(() => {
    try {
      let labels = props.configNode.nodelabels
      labels = labels.concat('|No Filter')
      setDropdownOptions(labels.split('|'))
    } catch (err) {
      //console.log(err)
    }
  }, [props.configNode])

  useEffect(() => {
    handleSearchName()
    // eslint-disable-next-line
  }, [label])

  const handleSearchName = async (/* event */) => {
    /* console.log(inputRef.current.value)
    const escapedString = escapeRegex(event.target.value)
    props.setSearchName(event.target.value) */
    const escapedString = escapeRegex(inputRef.current.value)
    props.setSearchName(inputRef.current.value)
    let tempArray = []
    if (label !== 'No Filter') {
      tempArray = await props.graphData.nodes
      //tempArray = await tempArray.filter(node => node.label === label)
      tempArray = await tempArray.filter(
        (node) =>
          node.label.localeCompare(label, undefined, {
            sensitivity: 'accent',
          }) === 0
      ) //case insensitive search for compatability
    }
    //if (event.target.value.length > 1) {
    if (inputRef.current.value.length > 1) {
      if (tempArray.length === 0) {
        tempArray = props.graphData.nodes
      }
      const regex = new RegExp(escapedString, 'i')
      let search = []
      await tempArray.forEach((node) => {
        for (const [key] of Object.entries(node)) {
          if (regex.test(node[key])) {
            const index = search.findIndex((item) => item === node)
            if (index === -1) {
              search.push(node)
            }
          }
        }
      })
      props.setSearchArray(search)
    } else {
      props.setSearchArray(tempArray)
    }
  }

  const resetSearch = () => {
    props.setSearchArray([])
    setLabel('No Filter')
    props.setSearchName('')
  }

  const highlightOnly = () => {
    if (props.searchArray.length > 0) {
      const newGraphData = {
        links: [],
        nodes: props.searchArray,
      }
      props.setGraphData(newGraphData)
    }
  }

  return (
    <div className='is-flex is-flex-direction-row'>
      <div>
        <TextInput
          placeholder='Search name'
          value={props.searchName}
          onChange={handleSearchName}
          refProp={inputRef}
        />
        {props.searchArray.length > 0 && (
          <NodeList searchArray={props.searchArray} setFocus={props.setFocus} />
        )}
      </div>
      <div>
        <Dropdown
          select={handleChangeLabel}
          label={label}
          options={dropdownOptions}
        />
      </div>
      <div>
        <button
          className='button is-danger clear-button'
          onClick={resetSearch}
          title='Clear'
        >
          <MdClear />
        </button>
      </div>
      <div>
        <Button
          title={'Highlighted only'}
          value={<BiShow />}
          click={highlightOnly}
        />
      </div>
    </div>
  )
}
