import React, { useState, useEffect } from 'react'
import Button from '../buttons/confirm/confirm'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Popup from 'reactjs-popup'
import { createRef } from 'react/cjs/react.production.min'

export default function ModalNodeInfo(props) {
  const [pictures, setPictures] = useState([])
  const [curPic, setCurPic] = useState('')
  const [curPicNum, setCurPicNum] = useState(undefined)
  const [nodePicCount, setNodePicCount] = useState('')
  const [showPicControls, setPicControls] = useState(false)
  const [properties, setProperties] = useState({})
  const inputValue = createRef()
  const [newValue, setNewValue] = useState()

  const fetchPicture = async (uuid) => {
    const data = await props.sendRequest(
      `api/v1/search?type=Picture&text=${uuid}`,
      'GET'
    )
    setCurPic(`data:${data.mimeType};base64,${data.imgBuffer}`)
  }

  const getPictures = async () => {
    const picData = await props.sendRequest(
      `node/linked?nodeKey=uuid&nodeValue=${props.selectedObject.uuid}&relName=HAS_PICTURE`,
      'GET'
    )
    try {
      setPictures(picData)
      if (picData[0]) {
        setPicControls(true)
        setNodePicCount(picData.length)
        setCurPicNum(1)
        fetchPicture(picData[0].fileUUID)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const nextPic = () => {
    if (curPicNum === nodePicCount) {
      fetchPicture(pictures[0].fileUUID)
      setCurPicNum(1)
    } else {
      fetchPicture(pictures[curPicNum].fileUUID)
      setCurPicNum(curPicNum + 1)
    }
  }

  const previousPic = () => {
    if (curPicNum === 1) {
      fetchPicture(pictures[nodePicCount - 1].fileUUID)
      setCurPicNum(nodePicCount)
    } else {
      fetchPicture(pictures[curPicNum - 2].fileUUID)
      setCurPicNum(curPicNum - 1)
    }
  }

  useEffect(() => {
    try {
      getPictures()
    } catch (err) {
      console.log(err)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let nodeData
    const token = localStorage.getItem('token')
    async function fetchNode(uuid) {
      try {
        const res = await fetch(
          `${props.baseUrl}/${props.selectedObject.type}?key=uuid&value=${uuid}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'bearer ' + token,
            },
          }
        )
        nodeData = await res.json()
        if (
          nodeData.hasOwnProperty('createDate') &&
          nodeData.hasOwnProperty('Uniq-id')
        ) {
          var createDate = new Date(nodeData.createDate * 1).toUTCString()
          nodeData.createDate = createDate
        } else if (nodeData.hasOwnProperty('createDate')) {
          createDate = new Date(nodeData.createDate * 1).toUTCString()
          nodeData.createDate = createDate
        }
        if (nodeData.hasOwnProperty('modifyDate')) {
          var modifyDate = new Date(nodeData.modifyDate * 1).toUTCString()
          nodeData.modifyDate = modifyDate
        }
        if (nodeData.hasOwnProperty('Time')) {
          var Time = new Date(nodeData.Time * 1).toUTCString()
          nodeData.Time = Time
        }
        if (nodeData.hasOwnProperty('Time_Connection')) {
          var Time_Connection = new Date(
            nodeData.Time_Connection * 1
          ).toUTCString()
          nodeData.Time_Connection = Time_Connection
        }
        if (nodeData.hasOwnProperty('Time_Disconnection')) {
          var Time_Disconnection = new Date(
            nodeData.Time_Disconnection * 1
          ).toUTCString()
          nodeData.Time_Disconnection = Time_Disconnection
        }
        if (nodeData.hasOwnProperty('Time_Human_Connection')) {
          var Time_Human_Connection = new Date(
            nodeData.Time_Human_Connection * 1
          ).toUTCString()
          nodeData.Time_Human_Connection = Time_Human_Connection
        }
        if (nodeData.hasOwnProperty('last_seen')) {
          var last_seen = new Date(nodeData.last_seen * 1).toUTCString()
          nodeData.last_seen = last_seen
        }
        const truncate = (str, n) => {
          return str?.length > n ? str.substr(0, n - 1) + '...' : str
        }
        if (nodeData.hasOwnProperty('PublishKey')) {
          var PublishKey = truncate(nodeData.PublishKey, 20)
          nodeData.PublishKey = PublishKey
        }
        setProperties(nodeData)
      } catch (error) {
        console.error(error)
      }
      // console.log(props.selectedObject)
    }
    if (props.selectedObject) {
      fetchNode(props.selectedObject.uuid)
    }
  }, [props.selectedObject, props.baseUrl])

  const keyNameToUpperCase = (string) => {
    return string.replace(string.charAt(0), string.charAt(0).toUpperCase())
  }

  const submitValue = async (e) => {
    e.preventDefault()
    console.log(typeof propValue)
    console.log(properties['Uniq-id'])
    const body = {
      nodeKey: 'uuid',
      nodeValue: properties.uuid,
      propKey: 'Configuration',
      propValue: inputValue.current.value,
      value: properties['Uniq-id'],
    }
    if (inputValue.current.value) {
      await props.sendRequest('node/configure', 'POST', body)
    }
    setNewValue({ ...properties, [body.propKey]: body.propValue })
  }

  const listProperties = () => {
    //console.log(newValue)
    var propArray = []
    for (const [key, value] of Object.entries(properties)) {
      if (
        props.user.permissionLevel === 'admin' ||
        (key !== 'uuid' &&
          key !== 'id' &&
          key !== 'createDate' &&
          key !== 'modifyDate' &&
          key !== 'owner' &&
          key !== 'group')
      ) {
        if (!['id', 'uuid'].includes(key)) {
          propArray.push(
            <TableRow key={key}>
              <TableCell>{keyNameToUpperCase(key)}</TableCell>

              {key === 'Configuration' ? (
                <TableCell>
                  <label className='label'>
                    <div>
                      {newValue === undefined ? value : newValue.Configuration}
                    </div>
                  </label>
                  <Popup trigger={<button>Edit</button>} position='top left'>
                    {(close) => (
                      <div
                        style={{
                          backgroundColor: 'gray',
                          marginBottom: '20px',
                        }}
                      >
                        <textarea
                          style={{ height: '300px' }}
                          defaultValue={JSON.stringify(
                            JSON.parse(value),
                            null,
                            2
                          )}
                          ref={inputValue}
                        ></textarea>
                        <div>
                          <button
                            className='submit'
                            style={{ marginRight: '10px' }}
                            onClick={submitValue}
                          >
                            Submit
                          </button>
                          <button className='close' onClick={close}>
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  </Popup>
                </TableCell>
              ) : (
                <TableCell>{value}</TableCell>
              )}
            </TableRow>
          )
        }
      }
    }
    return propArray
  }

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          {/* <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell >Value</TableCell>
          </TableRow>
        </TableHead> */}
          <TableBody>{listProperties()}</TableBody>
        </Table>
      </TableContainer>

      <hr className='mt-2 mb-2' />
      <div className={showPicControls ? '' : 'element-hide'}>
        <label className='label'>
          Picture {curPicNum}/{nodePicCount}{' '}
        </label>
        <div className='is-flex is-flex-direction-row is-align-items-center is-justify-content-center'>
          <Button value='<' click={previousPic} />
          <div className='modal-image box'>
            <img className='' src={curPic} alt='' />
          </div>
          <Button value='>' click={nextPic} />
        </div>
      </div>
    </>
  )
}
