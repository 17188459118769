import React from 'react'
// import ModalConfirmDelete from './confirmDelete'
import ModalCreateNode from './createNode'
import ModalCreateNodeWithRel from './createNodeWithRel'
import ModalEditNode from './editNode'
import ModalNodeInfo from './nodeInfo'
import ModalUploadFile from './uploadFile'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import EditForm from '../editor/editForm'
import Delete from './delete'

export default function Modal(props) {
  function chooseModal(choice) {
    switch (choice) {
      case 'newNode':
        return (
          <ModalCreateNode
            sendRequest={props.sendRequest}
            graphData={props.graphData}
            user={props.user}
            configNode={props.configNode}
            closeModal={props.closeModal}
            setGraphData={props.setGraphData}
          />
        )
      case 'confirmDelete':
        return (
          // <ModalConfirmDelete
          //   sendRequest={props.sendRequest}
          //   graphData={props.graphData}
          //   closeModal={props.closeModal}
          //   setGraphData={props.setGraphData}
          //   selectedObject={props.selectedObject}
          //   baseUrl={props.baseUrl}
          // />
          <Delete
            selectedObject={props.selectedObject}
            closeModal={props.closeModal}
          />
        )
      case 'newNodeWithRel':
        return (
          <ModalCreateNodeWithRel
            sendRequest={props.sendRequest}
            graphData={props.graphData}
            user={props.user}
            configNode={props.configNode}
            closeModal={props.closeModal}
            setGraphData={props.setGraphData}
            selectedObject={props.selectedObject}
          />
        )
      case 'editNode':
        return (
          <ModalEditNode
            sendRequest={props.sendRequest}
            selectedObject={props.selectedObject}
            graphData={props.graphData}
            user={props.user}
            closeModal={props.closeModal}
            setGraphData={props.setGraphData}
            baseUrl={props.baseUrl}
          />
        )
      case 'nodeInfo':
        return (
          <ModalNodeInfo
            sendRequest={props.sendRequest}
            selectedObject={props.selectedObject}
            user={props.user}
            closeModal={props.closeModal}
            setGraphData={props.setGraphData}
            baseUrl={props.baseUrl}
          />
        )
      case 'uploadFile':
        return (
          <ModalUploadFile
            sendRequest={props.sendRequest}
            graphData={props.graphData}
            selectedObject={props.selectedObject}
            closeModal={props.closeModal}
            setGraphData={props.setGraphData}
            baseUrl={props.baseUrl}
          />
        )
      case 'editConfig':
        return (
          <EditForm
            sendRequest={props.sendRequest}
            graphData={props.graphData}
            selectedObject={props.selectedObject}
            closeModal={props.closeModal}
            setGraphData={props.setGraphData}
            baseUrl={props.baseUrl}
          />
        )
      default:
        ;<p>Default</p>
    }
  }

  return (
    <div className=''>
      <div className='modal-background' onClick={props.closeModal} />
      <Container>
        <Paper style={{ overFlow: 'auto' }} className='modal-content '>
          {chooseModal(props.modalType)}
        </Paper>
      </Container>
      <button
        className='modal-close is-large'
        aria-label='close'
        onClick={props.closeModal}
      />
    </div>
  )
}
