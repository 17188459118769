import axios from 'axios'
import querystring from 'querystring'

const qs = querystring.stringify

class Axios {
  #instance = axios.create({
    baseURL: 'http://server.nome.fi:3011/',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  #instance2 = axios.create({
    baseURL: 'http://server.nome.fi:3013/',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  get = async (id) =>
    await this.#instance.get('vertex', { data: qs({ id: id }) })

  postNode = async (node) => await this.#instance.post('vertex', qs(node))

  postLink = async (link) => await this.#instance2.post('edge', qs(link))

  delete = async (id) =>
    await this.#instance.delete('vertex', { data: qs({ id: id }) })
}

export default new Axios()
