import React, { useState, useEffect } from 'react'
import Button from '../buttons/confirm/confirm'
import TextInput from '../textfields/plainText'
import Dropdown from '../dropdown/dropdown'

export default function ModalCreateNodeWithRel(props) {
  const [label, setNodeLabel] = useState('Pick Label')
  const [nodeName, setNodeName] = useState('')
  const [relName, setRelName] = useState('')
  const [relDirection, setRelDirection] = useState('in')
  const [dropdownOptions, setDropdownOptions] = useState(['company', 'person', 'vehicle', 'part'])

  const handleChangeLabel = (label) => {
    setNodeLabel(label)
  }

  const handleNodeChangeName = (event) => {
    setNodeName(event.target.value)
  }

  const handleRelChangeName = (event) => {
    setRelName(event.target.value)
  }

  useEffect(() => {
    try {
      let labels = props.configNode.nodelabels
      setDropdownOptions(labels.split('|'))
    } catch (err) {
      console.log(err)
    }
  }, [props.configNode])

  const handleClick = async () => {
    let graphData = props.graphData
    if (nodeName && relName && label !== 'Pick Label') {
      let bodyRel
      const newRelName = relName.replaceAll(' ', '_').toUpperCase()
      const bodyNode = {
        label: label,
        name: nodeName,
        owner: props.user.owner,
        group: props.user.group
      }
      const createdNode = await props.sendRequest('node', 'POST', bodyNode)
      console.log(createdNode)
      if (relDirection === 'in') {
        bodyRel = {
          startKey: 'uuid',
          startValue: props.selectedObject.uuid,
          endKey: 'uuid',
          endValue: createdNode.uuid,
          relName: newRelName
        }
        console.log('in')
      } else {
        bodyRel = {
          startKey: 'uuid',
          startValue: createdNode.uuid,
          endKey: 'uuid',
          endValue: props.selectedObject.uuid,
          relName: newRelName
        }
        console.log('out')
      }
      const createdRel = await props.sendRequest('relationship', 'POST', bodyRel)
      graphData.nodes.push(createdNode)
      graphData.links.push(createdRel)
      let newGraphData = {...graphData}
      props.setGraphData(newGraphData)
      //graphRef.current.d3Force('link', d3.forceLink(props.data.links).id(d => d.id))
      setNodeName('')
      props.closeModal()
    }
  }

  return (
    <div className="is-flex is-flex-direction-column p-2">
      <div className="is-flex is-flex-direction-row">
        <div className="is-flex is-flex-direction-column">
          <label className="label mt-2 mb-2">Node Label</label>
          <Dropdown select={handleChangeLabel} label={label} options={dropdownOptions} />
        </div>
        <div className="is-flex is-flex-direction-column">
          <label className="label mt-2 mb-2">Relationship direction</label>
          <div className="control">
            <label className="radio">
              <input type="radio" name="direction" onChange={() => setRelDirection('in')} checked/>
              In new node
            </label>
            <label className="radio">
              <input type="radio" name="direction" onChange={() => setRelDirection('out')} />
              Out new node
            </label>
          </div>
        </div>
      </div>
      <div className="is-flex is-flex-direction-row">
        <div className="is-flex is-flex-direction-column">
          <label className="label mt-2 mb-2">Node Name</label>
          <TextInput placeholder="name" value={nodeName} onChange={handleNodeChangeName} />
        </div>
        <div className="is-flex is-flex-direction-column">
          <label className="label mt-2 mb-2">Relationship Name</label>
          <TextInput placeholder="name" value={relName} onChange={handleRelChangeName} />
        </div>
      </div>
      <div className="m-4" />
      <Button value={`Create Node`} click={handleClick} />
      <div className="" />
    </div>
  )

}