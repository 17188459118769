import React, { useState, useEffect, useRef } from 'react'
import ForceGraph2D from 'react-force-graph-2d'
import * as d3 from 'd3'
import TextInput from '../textfields/plainText'
import Button from '../buttons/confirm/confirm'
import context from '../context'

export default function ForceGraph(props) {
  const [windowWidth, setWindowWidth] = useState(0)
  const [windowHeight, setWindowHeight] = useState(0)
  const [nodeFromName, setFromName] = useState('not selected')
  const [nodeToName, setToName] = useState('not selected')
  const [uuid1, setUuid1] = useState(undefined)
  const [uuid2, setUuid2] = useState(undefined)
  const [relType, setRelType] = useState('')
  const [initialCenter, setInitialCenter] = useState(true)
  const [zoomLevel, setZoomLevel] = useState('')
  // eslint-disable-next-line
  const [highlightNode, setHighlightNode] = useState(null)
  const [highlightLink, setHighlightLink] = useState(null)
  const graphRef = useRef()
  const NODE_SIZE = 20

  const { root } = React.useContext(context)
  const select = root.select

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }

  const handleRelOnChange = (event) => {
    setRelType(event.target.value)
  }

  const createRelationship = async (start, end, rel) => {
    const body = {
      startKey: 'uuid',
      startValue: start,
      endKey: 'uuid',
      endValue: end,
      relName: rel,
    }
    return props.sendRequest('relationship', 'POST', body)
  }

  useEffect(() => {
    // graphRef.current.d3Force('charge').strength(-2000)
    //graphRef.current.d3Force('charge').distanceMax(800)
    //graphRef.current.d3Force('charge').distanceMin(50)
    //graphRef.current.d3Force('link').distance(60)
    graphRef.current.d3Force('collide', d3.forceCollide(60))
    resizeWindow()
    window.addEventListener('resize', resizeWindow)
    return () => window.removeEventListener('resize', resizeWindow)
  }, [])

  useEffect(() => {
    if (props.nodeToFocus) {
      focusNode(props.nodeToFocus)
    }
  }, [props.nodeToFocus])

  // XXX: Right clicking node
  const handleNodeLeftClick = async (node, event) => {
    if (props.editRel) handleCreateLink(node)
    else {
      if (event.shiftKey) {
        if (select.get().find((n) => n === node)) {
          select.set(select.get().filter((n) => n !== node))
        } else select.add([node])
      } else {
        if (select.get().find((n) => n === node)) {
          let selection = node
          selection.type = 'node'
          props.selectNode(selection)
          graphRef.current.centerAt(node.x, node.y, 1000)
          graphRef.current.zoom(8, 2000)
          props.toggleModal('nodeInfo')
        } else select.set([node])
      }
    }
  }

  const getNode = async (nodeValue) => {
    const res = await props.sendRequest(
      `node/linked?nodeKey=uuid&nodeValue=${nodeValue}&relName=HASSTATUS`,
      'GET'
    )
    console.log(res)
    if (res[0] !== undefined) {
      props.getSSHNode(res[0].name)
    }
  }

  const handleNodeRightClick = async (node, x, y) => {
    let selection = node
    selection.type = 'node'
    props.selectNode(selection)
    if (node['Uniq-id'] !== undefined) {
      await getNode(node.uuid)
    }
    contextMenu(x, y, 'node')
  }

  const handleLinkRightClick = (link, x, y) => {
    let selection = link
    selection.type = 'relationship'
    props.selectNode(selection)
    contextMenu(x, y, 'rel')
  }

  const contextMenu = (x, y, type) => {
    props.contextMenuPos(x, y)
    props.openContextMenu(type)
  }

  const clearSelectedNodes = () => {
    setUuid1(undefined)
    setUuid2(undefined)
    setFromName('not selected')
    setToName('not selected')
    setRelType('')
  }

  const handleCreateRel = async () => {
    if (uuid1 && uuid2 && relType) {
      let graphData = props.data
      const relTypeNew = relType.replaceAll(' ', '_').toUpperCase()
      const rel = await createRelationship(uuid1, uuid2, relTypeNew)
      clearSelectedNodes()
      graphData.links.push(rel)
      let newGraphData = { ...graphData } //Object.assign({}, graphData)
      props.setGraphData(newGraphData)
      //graphRef.current.d3Force('link', d3.forceLink(props.data.links).id(d => d.id))
    }
  }

  const handleCreateLink = async (node) => {
    if (!uuid1) {
      setUuid1(node.uuid)
      setFromName(node.name)
      console.log('uuid1')
    } else if (!uuid2) {
      if (uuid1 !== node.uuid) {
        setUuid2(node.uuid)
        setToName(node.name)
        console.log('uuid2')
      }
    }
  }

  const focusNode = (node) => {
    graphRef.current.centerAt(node.x, node.y, 500)
    graphRef.current.zoom(1, 500)
  }

  return (
    <div>
      <div
        className={
          props.editRel
            ? 'box top-right-absolute z-1 background-transparent'
            : 'element-hide'
        }
      >
        <div>Create Relationship</div>
        <div>From node: {nodeFromName}</div>
        <div>To node: {nodeToName}</div>
        <div>With type:</div>
        <TextInput
          placeholder='Type'
          value={relType}
          onChange={handleRelOnChange}
        />
        <div className='is-flex is-flex-direction-row'>
          <div className='m-2'>
            <Button value='Create' click={handleCreateRel} />
          </div>
          <div className='m-2'>
            <Button value='Clear' click={clearSelectedNodes} />
          </div>
        </div>
      </div>
      <ForceGraph2D
        ref={graphRef}
        graphData={props.data}
        height={windowHeight}
        width={windowWidth}
        nodeRelSize={NODE_SIZE}
        nodeLabel={(node) => node.name}
        nodeAutoColorBy='level'
        linkDirectionalArrowLength={13}
        linkWidth={(link) => {
          if (link === highlightLink) {
            return 8
          } else {
            return 2
          }
        }}
        cooldownTicks={300}
        // maxZoom={10}
        // minZoom={0.1}
        onZoom={() => {
          if (!initialCenter) setZoomLevel(graphRef.current.zoom())
        }}
        enableNodeDrag={false}
        onEngineStop={() => {
          if (initialCenter) {
            // graphRef.current.zoomToFit(500)
          }
          setInitialCenter(false)
        }}
        onBackgroundClick={() => select.set([])}
        onBackgroundRightClick={(event) => {
          contextMenu(event.pageX, event.pageY, 'bg')
        }}
        dagMode='radialout'
        dagLevelDistance={500}
        onNodeClick={(node, event) => {
          handleNodeLeftClick(node, event)
        }}
        onNodeRightClick={(node, event) => {
          handleNodeRightClick(node, event.pageX, event.pageY)
        }}
        onNodeHover={(node) => {
          setHighlightNode(node)
        }}
        onLinkRightClick={(link, event) => {
          handleLinkRightClick(
            link,
            event.pageX,
            event.pageY
          ) /* props.toggleModal('editNode') */
        }}
        onLinkHover={(link) => {
          setHighlightLink(link)
        }}
        nodeCanvasObjectMode={() => 'before'}
        nodeCanvasObject={(node, ctx) => {
          //if (node === highlightNode) {
          if (select.get().find((n) => n === node)) {
            ctx.beginPath()
            ctx.arc(node.x, node.y, NODE_SIZE * 2, 0, 2 * Math.PI)
            //ctx.fillStyle = node === highlightNode ? 'red' : ''
            ctx.fillStyle = select.get().find((n) => n === node) ? 'red' : ''
            ctx.fill()
          }
          if (zoomLevel > 0.9) {
            const label = node.name
            const fontSize = 12
            ctx.font = `${fontSize}px Sans-Serif`
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = 'black'
            //ctx.fillText(label, node.x, node.y + 2.5)
            ctx.fillText(label, node.x, node.y + 27)
          }
        }}
      />
    </div>
  )
}
