import React from 'react'
import context from '../context'

export default function NodeList(props) {
  const { root } = React.useContext(context)

  const listNodes = () => {
    let nodeArray = []
    props.searchArray.forEach((node, index) => {
      const findName = () => {
        const key = Object.keys(node).find((key) =>
          new RegExp('NAME', 'i').test(key)
        )
        if (key) return node[key]
        else return node.id
      }

      nodeArray.push(
        <div
          className='p-1 m-1 is-clickable'
          key={node.id}
          onClick={() => {
            root.select.set([node])
            props.setFocus(node)
          }}
        >
          {node.name ? node.name : findName()}
        </div>
      )
    })
    return nodeArray
  }

  return (
    <div className='is-flex is-flex-direction-column node-list has-background-light'>
      {listNodes()}
    </div>
  )
}
