import React from 'react'
import context from '../context'
import Axios from '../axios'
import { Button, Box } from '@mui/material'

const Delete = (props) => {
  const { root } = React.useContext(context)
  const select = root.select.get()
  const node = root.node.get()

  if (!select.includes(node)) root.select.set([node])

  const handleDelete = async () => {
    const ids = select.map((object) => object.id)
    console.log(ids)

    await Promise.all(ids.map((id) => Axios.delete(id)))
      .then(() => root.data.refresh())
      .catch((err) => console.error(err))

    props.closeModal()
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <label className='m-2'>
        Delete{' '}
        {select.length === 1
          ? select[0].name
          : select.map((item) => item.name).join(', ')}
        ?
      </label>
      <Box className='is-flex is-flex-direction-row mt-3 is-justify-content-space-evenly'>
        <Button onClick={handleDelete}>Delete</Button>
        <Button onClick={props.closeModal}>Cancel</Button>
      </Box>
    </Box>
  )
}

export default Delete
