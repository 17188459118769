import React, { useState, useEffect, useRef } from 'react'
import DropdownItem from './dropdownItem'

export default function Dropdown(props) {
  const [showDropdown, setDropdown] = useState(false)
  const dropdownRef = useRef()

  const toggleDropdown = () => {
    setDropdown(!showDropdown)
  }

  useEffect(() => {
    const handleOutsideClicks = (event) => {
      if (
        showDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClicks)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClicks)
    }
  }, [showDropdown])

  const listOptions = (array) => {
    let dropdownArray = []
    array.forEach((element) => {
      dropdownArray.push(
        <DropdownItem text={element} select={props.select} key={element} />
      )
    })
    return dropdownArray
  }

  return (
    <div
      className={showDropdown ? 'dropdown is-active' : 'dropdown'}
      onClick={toggleDropdown}
      ref={dropdownRef}
    >
      <div className='dropdown-trigger'>
        <button className='button'>
          <span>{props.label}</span>
          <span className='icon is-small'>
            <i className='fas fa-angle-down'></i>
          </span>
        </button>
      </div>
      <div className='dropdown-menu' id='dropdown-menu' role='menu'>
        <div className='dropdown-content'>{listOptions(props.options)}</div>
      </div>
    </div>
  )
}
